@use 'reset';
@use '_variables.scss' as *;
@use '_mixins.scss' as *;

/* Main css */
html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: var(--base-100);
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  // No reload on up scroll
  font-size: 10px;
  caret-color: var(--accent-500);
  accent-color: var(--accent-500);
}

*:focus {
  outline: none;
}

*,
body {
  font-family: Poppins, Quicksand, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

body {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  color: var(--base-200);
  background: var(--base-700);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--base-100);
  text-wrap: balance;
}

h1 {
  color: var(--base-200);
  font-weight: 800;
  letter-spacing: var(--letter-spacing-title);
  font-size: 2.4rem;
  line-height: calc(2.4rem * 1.2);
}

h2 {
  color: var(--base-100);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.3);
}

h3 {
  color: var(--base-100);
  letter-spacing: var(--letter-spacing-text);
  font-weight: 600;
  font-size: 1.5rem;
  line-height: calc(1.5rem * 1.3);
}

h4 {
  color: var(--base-100);
  letter-spacing: var(--letter-spacing-text);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
}

p {
  letter-spacing: var(--letter-spacing-text);
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
}

button {
  font-weight: 400;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 1.4rem 1.6rem;
  border-radius: 2.4rem;
  border: var(--border-size) solid transparent;
  color: var(--base-100);
  letter-spacing: var(--letter-spacing-text);
  cursor: pointer;
  font-size: 1.2rem;
  line-height: calc(1.3rem * 1.3);
}

input {
  border: none;
  background: none;
  font-weight: 500;
  @include transitionOut();
}

a {
  cursor: pointer;
  color: var(--accent-500);

  &:hover {
    color: var(--accent-300);
  }
}

cdk-virtual-scroll-viewport {
  height: 100%;
}

.section-number {
  font-weight: 400;
}

.background {
  z-index: -1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  img {
    opacity: 90%;
    object-fit: cover;
    height: 100%;
    width: 100%;
    background: var(--base-300);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    inset: 0;
    backdrop-filter: blur(1px);
  }
}

.button-accent {
  color: var(--base-900);
  font-weight: 600;
  background: linear-gradient(
    135deg,
    hsla(var(--accent-600-string), 0.9),
    hsla(var(--accent-500-string), 0.9),
    var(--accent-600),
    var(--accent-500)
  );
  background-size: 300% 300%;
  box-shadow: 0 0.6rem 1.2rem -0.4rem hsla(var(--accent-300-string), 40%);
  border: var(--border-size) solid var(--accent-600);
  padding: 1.2rem;
  @include strongTextShadow();
  @include transitionOut();

  &:hover {
    color: var(--base-800);
    background-position: 99%;
    box-shadow: 0 0.6rem 1.2rem -0.4rem hsla(var(--accent-200-string), 40%);
    @include transitionIn();
  }

  span {
    @include strongTextShadow();
    @include transitionOut('color, text-shadow');
  }

  &.animation {
    box-shadow: 0 0.2rem 1.8rem -0.8rem hsla(var(--accent-700-string), 40%);
    border-color: var(--accent-700);
    background-position: 99%;
    will-change: box-shadow, border-color, background-position;
    @include transitionIn('box-shadow, border-color, background-position');

    span {
      color: var(--base-800);
      text-shadow: 0 0.1rem 0.3rem hsla(var(--base-100-string), 10%);
      will-change: color, text-shadow;
      @include transitionIn('color, text-shadow');
    }
  }
}

html,
body {
  height: 100vh;
}

.app-page {
  position: absolute;
  display: grid;
  grid-template-rows: 3.2rem auto;
  gap: 1.6rem;
  height: 100%;
  width: 100%;
  padding-top: var(--page-padding-vertical);
  overflow-y: auto;

  &__title {
    text-align: center;
    font-weight: 400;
    padding: 0.5rem 0;
    color: var(--base-200);
    letter-spacing: var(--letter-spacing-text);
    font-size: 1.8rem;
    line-height: calc(1.8rem * 1.2);
    @include textShadow();
    @extend .non-selectable;

    + div {
      height: calc(100% - 2 * var(--page-padding-vertical) - 3.2rem);
      padding: 0 var(--page-padding-horizontal);
    }
  }
}

.app-page.page-with-action {
  padding-bottom: 6.4rem;
}

.discount {
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  border-radius: 0.8rem;
  padding: 0.2rem 0.8rem;
  background: hsla(var(--success-string), 50%);
  border: var(--border-size) solid hsla(var(--success-string), 80%);
  color: var(--base-800);
  font-weight: 600;
  box-shadow: 0 0.2rem 0.8rem -0.2rem hsla(var(--success-string), 50%);
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
  @include strongTextShadow();
  @include contrast-blur();
}

.container.hidden .top-bar {
  display: none !important;
}

.price {
  &__old {
    text-decoration: line-through solid var(--accent-700) var(--border-size);
    margin-left: 0.6rem;
    font-weight: 400;
  }
}

.non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

.medium-weight {
  font-weight: 600;
  color: var(--base-100);
}

.decimals {
  margin-left: -0.4rem;
  font-size: 0.9rem;
  line-height: calc(0.9rem * 1.4);
}

.transparent {
  opacity: 50%;
}

.clickable {
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}

.accordion-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0%;
  will-change: grid-template-rows, opacity;
  @include transitionOut('grid-template-rows, opacity');

  &.expanded {
    grid-template-rows: 1fr;
    opacity: 100%;
    will-change: grid-template-rows;
    @include transitionIn('grid-template-rows, opacity');
  }

  > * {
    overflow: hidden;
  }
}

.grayed-out {
  opacity: 40%;
  cursor: not-allowed;
}

.breadcrumb {
  color: var(--base-500);

  &__icon {
    display: inline-grid;
    align-items: center;
    justify-content: center;
    background: var(--base-600);
    color: var(--base-200);
    margin-right: 0.4rem;
    border-radius: 1.2rem;
    height: 4rem;
    width: 4rem;
    min-width: 4rem;
    @include transitionOut('background');
    @include glass-2(var(--base-500-string));

    svg {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  &:hover {
    .breadcrumb__icon {
      background: var(--base-500);
      @include transitionIn('background');
    }

    color: var(--accent-500);
  }
}

.divider {
  margin: 0.8rem auto 2.4rem auto;
  border-top: var(--border-size) solid var(--base-600);
  will-change: visibility, opacity, transform;
  @include transitionOut('visibility, opacity, transform');
}

.page {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 1.5rem;
      line-height: calc(1.5rem * 1.3);
    }

    &__number {
      font-weight: 400;
    }

    &__actions {
      display: flex;
      gap: 0.8rem;
    }
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--base-500) var(--base-700);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}

*::-webkit-scrollbar-track {
  background: var(--base-700);
  border-radius: 1.4rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--base-500);
  border-radius: 1rem;
  border: 0.3rem solid var(--base-700);
}

*::-webkit-scrollbar-track-piece {
  display: none;
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 4.8rem var(--base-800) inset;
  -webkit-text-fill-color: var(--base-100) !important;
  @include transitionIn();
}

.ant-alert-with-description .ant-alert-message {
  font-size: 1.4rem;
  line-height: calc(1.4rem * 1.3);
  font-weight: 500;
}

.ant-alert-warning {
  margin-top: 3.2rem;
  background-color: var(--warning-500);
  border: var(--border-size) solid var(--warning-300);
  @include glass-2(var(--warning-800-string));
}

.ant-alert-with-description .ant-alert-message {
  color: var(--base-100);
  margin: 0;
  mix-blend-mode: luminosity;
}

.ant-alert-with-description .ant-alert-description {
  color: var(--base-200);
  margin: 0;
  mix-blend-mode: luminosity;
}

.ant-alert-description {
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
}

.ant-layout-sider {
  height: 100%;
  flex: 0 0 auto !important;
  width: 100% !important;
  max-width: none !important;
  min-width: none !important;
}

.ant-layout-sider-light,
.ant-layout-sider-dark {
  background: none;
}

.ant-input-affix-wrapper > input.ant-input {
  background: none;
  border-radius: 0;
  backdrop-filter: none;
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
}

.ant-input-number {
  background-color: var(--base-700);
  @include glass-3(var(--base-800-string));
}

.ant-input-number-input {
  padding: 0 1.6rem;
  border-radius: 1.2rem;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--base-900);
}

.ant-input {
  background-color: var(--base-700);
  color: var(--base-200);
  border: var(--border-size) solid var(--base-800);
  font-weight: normal;
  padding: 1rem 0.8rem;
  border-radius: 1.2rem;
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
  @include glass-3(var(--base-800-string));

  &:hover {
    background-color: var(--base-800);
    border-color: var(--base-900);
  }

  &-affix-wrapper {
    color: var(--base-200);
    background-color: var(--base-700);
    border-color: var(--base-800);
    border-radius: 1.2rem;
    padding: 1rem 1.2rem;
  }

  &-number {
    font-weight: normal;
    border: var(--border-size) solid var(--base-800);
    font-size: 1.1rem;
    line-height: calc(1.1rem * 1.3);
  }
}

.ant-input:focus,
.ant-input-focused {
  background-color: var(--base-800);
  border-color: var(--base-900);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--base-900);
}

.ant-popover-inner {
  border: var(--border-size) solid var(--base-800);
  @include glass-3(var(--base-800-string));
}

.ant-popover-message {
  padding: 0.4rem 0 1.6rem;
}

a.ant-btn {
  padding: 0 0.4rem;

  &[disabled] {
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }
  }
}

.ant-popover-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.4rem;
  margin: 0;

  .ant-btn {
    cursor: pointer;
    color: var(--accent-500);
    background: none;
    border: none;
  }

  .ant-btn-primary {
    color: var(--base-900);
    border-color: var(--accent-600);
    background: var(--accent-500);
    border-radius: 1.2rem;
    background: hsla(var(--accent-400-string), 75%);
  }

  button {
    margin: 0;
  }
}

.ant-picker {
  padding: 1.2rem;
  border-radius: 1.2rem;
  background-color: var(--base-700);
  color: var(--base-200);
  border: var(--border-size) solid var(--base-800);
  @include glass-3(var(--base-800-string));
  @include transitionOut('background-color, border-color');
}

.ant-picker-panel-container {
  background-color: var(--base-700);
  border-radius: 1.2rem;
  border: var(--border-size) solid var(--base-900);
  @include glass-3(var(--base-900-string));
}

.ant-picker-input > input {
  font-weight: normal;
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: var(--accent-700);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed var(--base-400);
  border-bottom: 1px dashed var(--base-400);
  border-left: 1px dashed var(--base-400);
}

.ant-picker-cell {
  color: var(--base-500);
}

.ant-picker-cell-in-view {
  color: var(--base-200);
}

.ant-select-selection-placeholder,
.ant-picker-input > input {
  color: var(--base-400);
}

.ant-select-selection-placeholder,
.ant-picker-input > input,
.ant-input,
.ant-input-number-input {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--base-400);
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--base-400);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--base-400);
  }
}

.ant-select-item {
  color: var(--base-200);
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-select-item-group {
  color: var(--base-400);
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--base-100);
  background-color: var(--base-900);
  @include glass-4(var(--base-900-string));
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--base-900);
  @include glass-4(var(--base-900-string));
}

.ant-form-item,
.ant-select,
.ant-select-arrow,
.ant-form-item-label,
.ant-radio-button-wrapper label {
  font-size: 1rem;
}

.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0;
}

.ant-form-item-explain-error {
  margin-top: 0.2rem;
}

.ant-form label,
label {
  color: var(--base-100);
  font-weight: 600;
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
}

.ant-form-item-label > label {
  height: auto;
}

.ant-tooltip-inner {
  color: var(--base-900);
  font-weight: 300;
  font-size: 1.2rem;
  min-width: 3.2rem;
  min-height: 3.2rem;
  padding: 0.8rem 1.2rem;
  border-radius: 1.6rem;
  @include glass-4(var(--base-100-string));
  @include strongTextShadow();
}

.ant-tooltip-arrow-content {
  width: 1.2rem;
  height: 1.2rem;

  &::before {
    @include glass-4(var(--base-100-string));
  }
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content {
  transform: translateY(-1.2rem) rotate(45deg);
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: var(--accent-700);
}

.ant-menu-submenu-popup {
  .ant-menu.ant-menu:not(.ant-menu-horizontal) li {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: left;
    width: 100%;
  }

  .ant-menu-vertical .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .ant-menu-submenu-arrow {
      position: absolute;
    }
  }
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin: 0;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 1rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title:has(.ant-menu-submenu-title-custom) {
  display: block;
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  font-size: 1.2rem;
}

.ant-menu-sub.ant-menu-inline {
  background: hsla(var(--base-200-string), 5%);
}

.ant-menu-item .anticon + span {
  margin: 0;
}

.ant-menu-submenu-title a {
  width: 100%;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title {
  padding-left: 3.2rem !important;
  @include transitionIn();
}

.ant-menu-submenu-title {
  padding-left: 3.2rem !important;
  @include transitionOut();
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  padding-left: 3.2rem !important;
}

.ant-menu-sub[ng-reflect-nz-open='true'] .ant-menu-inline .ant-menu-title-content {
  padding-left: 2.4rem;
}

.ant-menu-sub[ng-reflect-nz-open='true'].ant-menu-inline {
  border-top: var(--border-size) solid hsla(var(--base-100-string), 10%);
  border-bottom: var(--border-size) solid hsla(var(--base-100-string), 10%);
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: hsla(var(--accent-700-string), 40%);
  border-top: var(--border-size) solid hsla(var(--accent-600-string), 40%);
  border-bottom: var(--border-size) solid hsla(var(--accent-600-string), 40%);
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  display: none;
}

.ant-modal-title {
  color: var(--base-200);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 2rem;
  line-height: calc(2rem * 1.2);
}

.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  right: 3.2rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

.ant-menu-submenu-selected ul li.ant-menu-submenu-selected a {
  color: var(--accent-200) !important;

  waytr-icon svg {
    path,
    circle,
    ellipse,
    polygon {
      fill: var(--accent-200) !important;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  @include transitionOut('background');
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background: var(--accent-700);
  @include transitionIn('background');
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--accent-400);
}

.ant-menu-submenu-selected {
  color: var(--accent-400);

  a {
    color: var(--accent-400);
  }
}

.ant-input-group-addon {
  border-radius: 1.2rem;
  background: none;
  border: none;
  color: var(--base-100);
  font-size: 1.2rem;
  padding: 0 1.2rem;
}

.ant-modal-body {
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
  padding: 2.4rem;
  color: var(--base-200);

  p {
    font-size: 1.2rem;
    line-height: calc(1.2rem * 1.3);
  }

  .ant-input-affix-wrapper {
    background-color: var(--base-800);
    border-color: var(--base-900);

    &:hover {
      background-color: var(--base-900);
      border-color: white;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--base-800);
    border-color: var(--base-900);

    &:hover {
      background-color: var(--base-900);
      border-color: white;
    }
  }
}

.ant-picker-range {
  height: 3.6rem;
  border-radius: 1.2rem;
  padding: 0.4rem 0.8rem;
  gap: 0.8rem;
  border: var(--border-size) solid var(--base-800);
  background-color: var(--base-800);
  @include glass-3(var(--base-800-string));

  .ant-picker-input > input {
    font-size: 1rem;
    line-height: calc(1rem * 1.3);
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 3.6rem;
}

.ant-form-item-control-input {
  min-height: 3.6rem;
}

.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector,
.ant-select[ng-reflect-mode='multiple']:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
  min-height: 3.6rem;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: var(--base-400);
  background: none;
  border: var(--border-size) solid var(--base-500);
  backdrop-filter: none;
}

.ant-steps-item-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--accent-500);
  border: var(--border-size) solid var(--accent-600);
  box-shadow: 0 1.2rem 2.4rem -1.2rem hsla(var(--accent-300-string), 40%);
  color: var(--base-900);
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: none;
  border: var(--border-size) solid var(--base-500);
  color: var(--base-500);
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: none;
  border: var(--border-size) solid var(--base-200);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--base-200);
}

.ant-steps-item-icon .ant-steps-icon {
  vertical-align: baseline;
  font-weight: 600;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--base-100);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 1.5rem;
  line-height: 2rem;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--base-400);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--base-400);
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--base-200);
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--base-200);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--base-200);
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--base-500);
  @include glass-2(var(--base-400-string));
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--base-200);
  @include glass-2(var(--base-100-string));
}

.ant-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 0.2rem;
  color: var(--base-200);
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
}

.ant-btn {
  display: inline-flex;
  gap: 0.4rem;
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  border-width: 0.1rem;
  box-shadow: none;
  height: 3.6rem;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin: 0;
}

.ant-tabs-tab {
  padding: 0 1.2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0.8rem;
}

.ant-tabs-tab-btn {
  color: var(--base-200);
  font-weight: 500;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--accent-100);
  font-weight: 500;
  text-shadow: none;
}

.ant-btn[nztype='default'] {
  color: var(--base-200);
  background: none;
  border-color: hsla(var(--base-400-string, 80%));
  border-radius: 1.2rem;
  @include transitionOut('color, border-color');

  &:hover,
  &:focus,
  &:active {
    color: var(--base-200);
    background: none;
    border-color: var(--base-400);
    @include transitionIn('color, border-color');
  }
}

.ant-btn[nztype='default']:focus {
  background-color: var(--base-800);
  border: var(--border-size) solid var(--base-900);
  @include glass-2(var(--base-900-string));
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: none;
  border-color: var(--base-500);
  color: var(--base-400);
}

.item-modal {
  min-width: 50vw;

  .ant-modal-header {
    display: none;
  }

  .ant-modal-content {
    padding-left: 40%;
  }

  .ant-modal-footer {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-radio-button-wrapper-disabled span {
  color: var(--base-500);
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: var(--base-400);
  background: transparent;
}

.ant-btn-primary {
  color: var(--base-900);
  border-color: var(--accent-600);
  background: var(--accent-500);
  border-radius: 1.2rem;
  padding: 0.4rem 1.2rem;
  box-shadow: 0 1.2rem 2.4rem -1.2rem hsla(var(--accent-500-string), 60%);
  @include glass-4(var(--accent-400-string));
  @include transitionOut('background, color, border-color');

  &:hover,
  &:focus,
  &:active {
    color: var(--base-900);
    border-color: var(--accent-700);
    background: var(--accent-500);
    @include transitionIn('background, color, border-color');
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  @include glass-2(var(--base-600-string));
  @include transitionOut('background, color, border-color');
}

.ant-pagination-item {
  border-radius: 1.2rem;
  background: none;
  border: none;
  @include transitionOut('color, border');

  &-active {
    border-radius: 1.2rem;
    font-weight: 600;
    background: var(--base-700);
    border: var(--border-size) solid var(--base-600);
    @include glass-2(var(--base-600-string));
  }

  &:hover {
    @include transitionIn('color, border');
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 1.2rem;
  padding: 0.4rem 0.8rem;
  gap: 0.8rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector[ng-reflect-mode='tags'] {
  padding: 0.2rem 3.2rem 0.2rem 0.8rem;
}

.ant-pagination-item a {
  @include transitionOut('font-weight, background, color, border');
}

.ant-pagination-item-active a {
  border-radius: 1.2rem;
  font-weight: 600;
  background: var(--base-700);
  @include glass-2(var(--base-600-string));
  @include transitionIn('font-weight, background, color, border');
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: var(--accent-200);
}

.anticon-question-circle {
  color: var(--base-300);
}

.ant-modal-mask,
.ant-image-preview-mask {
  mask-image: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 1) 100%);
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 1) 100%);
}

.ant-image-preview-mask {
  background: hsla(var(--base-400-string, 80%));
}

.ant-image-preview-operations,
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background: hsla(var(--base-200-string, 80%));
}

.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  background: hsla(var(--base-300-string, 80%));
}

.ant-modal-content {
  box-shadow: 0 0 4.8rem -2.4rem hsla(var(--base-100-string), 45%);
  border: var(--border-size) solid var(--base-900);
  @include glass-4(var(--base-800-string));
}

.ant-modal-header {
  border-bottom-color: var(--base-500);
  background: hsla(var(--base-900-string), 20%);
}

.ant-modal-footer {
  border-top-color: var(--base-500);
  background: hsla(var(--base-500-string), 20%);
}

.ant-modal-close-x {
  font-size: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;

  svg {
    fill: var(--base-300);
  }
}

.ant-steps-item-container {
  outline: none;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.ant-steps-item-icon .ant-steps-icon {
  top: 0rem;
}

.ant-steps-horizontal .ant-steps-item {
  padding-left: 1.6rem;
}

.ant-steps-item-icon {
  font-size: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  border-radius: 3.2rem;
  margin: 0 0.8rem 0 0;
}

.ant-steps-item-title::after {
  top: 1.8rem;
  height: 0.1rem;
}

.ant-notification-notice {
  padding: 1.6rem;
  background: var(--base-900);
  border: var(--border-size) solid white;
  box-shadow: 0 1.2rem 4.8rem -2.4rem hsla(var(--base-100-string), 60%);
  background: hsla(var(--base-900-string), 85%);
  @include strong-blur();
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: var(--base-200);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.2);
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  color: var(--base-300);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0.4rem;
  margin-left: 4rem;
  font-weight: 500;
  color: var(--base-400);
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 4rem;
  font-weight: 400;
  color: var(--base-300);
  padding-right: 3.2rem;
}

.ant-notification-notice-icon {
  margin-top: 0.4rem;
  margin-left: 0;
}

.ant-notification-close-icon {
  font-size: 1.2rem;
  color: var(--base-200);
}

.ant-notification-notice-content {
  svg {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

.ant-notification-notice-description:first-letter {
  text-transform: uppercase;
}

.ant-upload.ant-upload-select {
  padding: 1.2rem 1.6rem;
  background: var(--base-700);
  border: var(--border-size) solid var(--base-900);
  border-radius: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @include glass-3(var(--base-900-string));
  @include transitionOut('background, border');

  &:hover {
    @include glass-4(var(--base-900-string));
    border: var(--border-size) solid var(--base-900);
    @include transitionIn('background, border');
  }
}

.ant-upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 2.4rem 0;
}

.ant-upload-text {
  color: var(--base-200);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-title);
  margin: 0;
  font-size: 1.4rem;
  line-height: calc(1.4rem * 1.3);
}

.ant-upload-hint {
  color: var(--base-200);
  font-weight: 300;
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);
}

.ant-upload.ant-upload-drag {
  background: var(--base-700);
  border: var(--border-size) solid var(--base-800);
  @include transitionOut('background, border');
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  background: var(--base-800);
  border: var(--border-size) solid var(--base-900);
  @include transitionIn('background, border');
}

.ant-upload-list-item {
  height: auto;
}

.ant-upload-list-item-info {
  padding: 0.8rem;
  border-radius: 1.2rem;
  background: var(--base-700);
  border: var(--border-size) solid var(--base-800);
  @include glass-2(var(--base-800-string));
  @include transitionOut('background, border');
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
  border-radius: 1.6rem;
}

.ant-upload-list-item-info {
  border-radius: 1.6rem;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  border-color: var(--base-400);
  background-color: var(--base-700);
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background: var(--base-800);
  border: var(--border-size) solid var(--base-900);
  @include transitionIn('background, border');
}

.ant-upload-picture-card-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.ant-modal-body:has(.image-preview) {
  padding: 0;

  img {
    width: 100%;
    height: auto;
    border-radius: 1.6rem;
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  flex-direction: column;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 4rem;
  height: 4rem;
}

.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  margin-left: 0.8rem;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem -1.2rem hsla(var(--base-100-string), 60%);
  line-height: normal;
}

.ant-upload-list-text .ant-upload-span > *:first-child,
.ant-upload-list-picture .ant-upload-span > *:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--base-800);
  border-radius: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem -1.2rem hsla(var(--base-100-string), 60%);
  @include glass-2(var(--base-900-string));
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  line-height: normal;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
  height: auto;
  @include transitionOut();
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0.8rem 0;
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: var(--base-800);
  border-color: var(--base-900);
  @include transitionIn();
}

.ant-timeline-item {
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.3);

  &-content {
    padding-top: 0.4rem;
  }

  &-head {
    background: none;
    border: 0.2rem solid var(--accent-600);

    &-red {
      border-color: var(--accent-600);
    }

    &-gray,
    &-grey {
      border-color: var(--base-500);
    }
  }
}

.ant-timeline-item-tail {
  border-left: 0.2rem solid var(--base-500);
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto;
}

.search-bar {
  background-color: var(--base-600);
  padding: 0.8rem 1.2rem;
  color: var(--base-200);
  border-radius: 1.2rem;
  line-height: 1.4rem;
  font-weight: 300;
  min-height: 4rem;
  @include glass-1(var(--base-500-string));
  font-size: 1.2rem;
  line-height: 1.2rem;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--base-400);
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--base-400);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--base-400);
  }
}

.timeline {
  &__title {
    color: var(--base-200);
    font-weight: 400;
    font-size: 1.1rem;
    line-height: calc(1.1rem * 1.3);

    &.old {
      color: var(--base-300);
    }
  }

  &__description {
    color: var(--base-200);
    font-weight: 300;
    font-size: 1.1rem;
    line-height: calc(1.1rem * 1.3);

    &.old {
      color: var(--base-300);
    }
  }
}

.ant-select-item-option-content {
  font-size: 1.1rem;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
}

.ant-select-multiple .ant-select-selection-item {
  height: 100%;
  border-color: none;
  background: var(--base-500);
  color: var(--base-100);
  align-items: center;
  border-radius: 0.8rem;
  line-height: normal;
  margin: 0;
  padding: 0.8rem 0.4rem;
  border: var(--border-size) solid var(--base-400);
  @include glass-2(var(--base-400-string));
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  background: var(--base-500);
  color: var(--base-100);
  border: none;
  border-radius: 0.8rem;
  @include glass-2(var(--base-400-string));
  font-size: 0.9rem;
  line-height: calc(0.9rem * 1.4);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: var(--border-size) solid var(--base-800);
  background-color: var(--base-800);
  @include glass-3(var(--base-800-string));
}

.ant-color-picker-trigger {
  border-radius: 1.2rem;
  border: var(--border-size) solid var(--base-900);
  background-color: var(--base-900);
  @include glass-3(var(--base-900-string));
}

.ant-popover-message-icon {
  font-size: 1.5rem;
}

.ant-popover-message {
  color: var(--base-200);
  font-weight: 600;
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.3);
}

.ant-popover-inner-content {
  padding: 1.2rem;
}

.keyboard {
  &-overlay {
    .ant-popover-inner {
      border: var(--border-size) solid var(--base-400);
      background-color: var(--base-600);
      @include glass-4(var(--base-500-string));
    }
  }

  &__sum {
    .ant-input-affix-wrapper {
      height: 6.4rem;
      padding: 0.8rem 1.6rem;
      border: var(--border-size) solid var(--base-900);
      background: var(--base-800);
      box-shadow: 0 1.6rem 2.4rem -1.6rem hsla(var(--base-900-string), 60%);
      font-size: 2.4rem;
      line-height: calc(2.4rem * 1.2);
      @include transitionOut('background, box-shadow, border-color');

      &:hover {
        border-color: white;
        background: var(--base-900);
        box-shadow: 0 1.6rem 2.4rem -1.6rem hsla(var(--base-900-string), 80%);
        @include transitionIn('background, box-shadow, border-color');
      }

      .ant-input {
        width: 19.2rem;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: calc(2.4rem * 1.2);
      }
    }
  }
}

.ant-color-picker-color-block {
  border-radius: 0.8rem;
}

.ant-color-picker-color-block-inner {
  border: none;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--base-800);
}

.ant-select-multiple .ant-select-selection-item-remove {
  align-self: center;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  fill: var(--base-100);
  border: var(--border-size) solid var(--base-100);
  padding: 0.2rem;
  border-radius: 2.4rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.ant-table {
  background: none;
  font-size: 1.1rem;
  @include gpuRenderStyles();
}

table {
  font-size: 1.1rem;
}

.ant-table-wrapper {
  height: 100%;
  overflow: hidden;
  border-radius: 2.4rem;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .ant-table {
        max-height: 90%;
        overflow: auto;
        border-radius: 0;

        &-body {
          overflow-y: hidden !important;
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  input {
    @include glass-3(var(--base-900-string));
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    max-width: 24rem;
    @include glass-3(var(--base-900-string));
  }
}

.ant-table-filter-dropdown-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  padding: 1.2rem 0.8rem;
}

nz-filter-trigger:not(:last-child) {
  margin-right: 0.4rem;
}

nz-modal {
  display: none;
}

.ant-table-header,
.nz-table-hide-scrollbar {
  overflow: hidden !important;
}

.popover-select {
  width: 32rem !important;

  width: fit-content;

  .ant-popover-title {
    color: var(--base-200);
    padding: 1.2rem 1.6rem;
  }
}

.ant-select-tree-dropdown {
  width: 100%;
  max-height: 32rem;
  @include glass-3(var(--base-700-string));
}

.ant-popover {
  .ant-tree-select {
    width: 100%;
    max-height: 32rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--base-900);
    border-color: white;

    &:hover {
      background-color: var(--base-900);
      border-color: white;
    }
  }
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  padding: 1.2rem 0.8rem;
}

.ant-select-tree-checkbox {
  margin: 0.4rem 0.2rem 0 0;
}

.ant-select-tree-checkbox-inner::after {
  width: 0.4rem;
  height: 0.8rem;
}

.ant-table-filter-dropdown-btns {
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  border-top: var(--border-size) solid var(--base-700);
}

.ant-table-pagination.ant-pagination {
  margin: 2.4rem;
}

.ant-table-filter-dropdown {
  background: var(--base-900);
  border: var(--border-size) solid white;
  box-shadow: 0 1.2rem 4.8rem -2.4rem hsla(var(--base-100-string), 60%);
  @include blur();
  @include glass-2(var(--base-900-string));
  @include transitionOut('backdrop-filter');
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: var(--base-900);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background: white;
}

.ant-dropdown-menu {
  background: var(--base-900);
  @include blur();
  @include glass-2(var(--base-900-string));
  @include transitionOut('backdrop-filter');
}

tr.new {
  background: linear-gradient(to right, hsla(var(--success-300-string), 20%) 0%, transparent 80%);
  color: var(--success-100);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 1.6rem 2.4rem;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right,
.ant-table-thead tr > th.ant-table-cell-fix-left,
.ant-table-thead tr > th.ant-table-cell-fix-right {
  @include glass-4(var(--base-800-string), false);
}

.ant-table-row {
  @include transitionOut('background-color, color, font-weight');

  &.selected-row,
  &.editing-row {
    color: var(--base-100);
    font-weight: 500;
    @include glass-2(var(--base-500-string), false);
    @include transitionIn('background, color, font-weight');

    &:hover,
    &:focus,
    &:active {
      @include glass-2(var(--base-400-string), false);
      @include transitionIn('background');
    }
  }

  &:hover,
  &:focus,
  &:active {
    @include glass-2(var(--base-500-string), false);
    @include transitionIn('background, color, font-weight');
  }
}

.ant-table {
  color: var(--base-200);
  @include transitionOut('background-color, color, font-weight');

  &-title {
    padding: 2.4rem 2.4rem 0.8rem 2.4rem;
  }

  &-thead {
    tr > th {
      color: var(--base-200);
      background: none;
      padding: 1.6rem 2.4rem;
    }

    th.ant-table-column-sort {
      background: none;
    }
  }

  &-tbody > {
    tr > td {
      border-color: var(--base-700);
      padding: 1.6rem 2.4rem;
      vertical-align: middle;
    }

    tr.ant-table-row {
      &:nth-last-child(2) {
        > td {
          border-bottom: 0;

          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
        }

        &:hover td {
          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  &-tfoot {
    tr > th {
      padding: 1.6rem 2.4rem;
    }

    tr > td {
      padding: 1.6rem 2.4rem;
    }
  }

  table {
    border-radius: 3.2rem;
  }

  &-container {
    border-top-left-radius: 3.2rem;
    border-top-right-radius: 3.2rem;

    table > thead > {
      tr:first-child {
        th:first-child {
          border-top-left-radius: 3.2rem;
        }

        th:last-child {
          border-top-right-radius: 3.2rem;
        }
      }
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: transparent;
  border: var(--border-size) solid var(--base-500);
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: var(--border-size) solid var(--base-500);
  padding: 0 0.6rem;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  align-self: center;
  font-size: 1rem;
  line-height: calc(1rem * 1.4);
}

.ant-form-item-label {
  text-align: start;
}

.ant-input {
  font-size: 1rem;
  line-height: calc(1rem * 1.4);
}

.ant-input[disabled] {
  color: var(--base-400);
}

.ant-input-affix-wrapper:hover {
  border-color: var(--base-900);
  background: var(--base-800);
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: var(--base-500);
  background: transparent;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--base-900);
  background: var(--base-800);
}

.ant-input-number {
  width: auto;
  border-radius: 1.2rem;
}

.ant-input-number:hover {
  border-color: var(--base-900);
  background-color: var(--base-800);
  @include transitionIn('border-color, background-color');
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 2.4rem 0;
}

.ant-badge {
  align-self: center;

  &-count {
    color: var(--accent-200);
    border: var(--border-size) solid var(--base-800);
    box-shadow: 0 1.2rem 2.4rem -1.2rem hsla(var(--base-700-string), 40%);
    font-weight: 600;
    @include glass-4(var(--base-800-string), false);
    @include blur();
    font-size: 1rem;
    line-height: calc(1rem * 1.4);
    @include transitionOut('backdrop-filter');

    &-sm {
      border-radius: 0.5rem;
      padding: 0 0.2rem;
    }
  }
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  color: var(--accent-200);
  font-size: 1rem;
  line-height: calc(1rem * 1.4);
  @include textShadow();
}

.ant-switch {
  border: var(--border-size) solid var(--base-500);
  @include glass-3(var(--base-400-string), false);

  &-handle {
    top: 0.3rem;
    left: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  &-checked {
    border: none;
    @include glass-4(var(--accent-500-string), false);
  }

  &-handle::before {
    border: var(--border-size) solid var(--base-800);
    box-shadow: 0 0.4rem 1.2rem -0.4rem hsla(var(--base-100-string), 20%);
    @include glass-3(var(--base-800-string), false);
  }
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 4rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 0 1.6rem;
  line-height: 4rem;
}

.ant-skeleton-element {
  width: 4.8rem;
  height: 4.8rem;
  min-width: 100%;
  border-radius: inherit;
  @include transitionOutSlow('transform');
  @include glass-3(var(--base-400-string));

  .ant-skeleton-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;

    &-svg {
      width: 50%;
      height: auto;
    }
  }

  .ant-skeleton-image-path {
    fill: var(--base-800);
  }
}

.ant-skeleton-input {
  min-width: auto !important;
  width: 100% !important;
  height: 100%;
}

.ant-radio-group {
  padding: 0.2rem 0;
  min-height: 3.2rem;
}

.ant-radio-button-wrapper {
  border-color: var(--base-800);
  height: auto;
  padding: 0.8rem 1.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include glass-3(var(--base-800-string), false);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--base-900);
  background: var(--accent-700);
  border-color: var(--accent-600);
}

.ant-radio-button-wrapper:first-of-type {
  border-radius: 1rem 0 0 1rem;
}

.ant-radio-button-wrapper:last-of-type {
  border-radius: 0 1rem 1rem 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: none;
}

.ant-radio-button-wrapper:focus-within,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-button-wrapper:not(:first-of-type)::before {
  @include glass-3(var(--base-700-string), false);
}

.ant-empty-img-simple-ellipse {
  fill: var(--accent-500);
}

.ant-empty-img-simple-g {
  stroke: var(--accent-600);
}

.ant-empty-img-simple-path {
  fill: var(--accent-600);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: var(--accent-700);
}

.ngx-charts {
  > g {
    transform: translate(4.8rem, 2.4rem);
  }
}

/* Hide scrollbar for IE, Edge add Firefox on mobile */
@media all and (max-width: 768px) {
  .no-scrollbar {
    scrollbar-color: transparent transparent;
  }

  .no-scrollbar::-webkit-scrollbar {
    height: 0;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }

  .no-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }

  .no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0, 0, 0, 0);
  }
}
