@use 'src/styles/_variables.scss' as *;
@use 'sass:math';

// Transitions
@keyframes fadein {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

// Mixins
@mixin accent-gradient100() {
  background: linear-gradient(40deg, var(--accent-700), var(--accent-700));
}

@mixin accent-gradient200() {
  background: linear-gradient(40deg, var(--accent-700), var(--accent-700));
}

@mixin glass-1($color1, $blur: true) {
  background: hsla($color1, 30%);

  @if $blur {
    @include strong-blur();
  }
}

@mixin glass-2($color1, $blur: true) {
  background: hsla($color1, 45%);

  @if $blur {
    @include strong-blur();
  }
}

@mixin glass-3($color1, $blur: true) {
  background: hsla($color1, 60%);

  @if $blur {
    @include strong-blur();
  }
}

@mixin glass-4($color1, $blur: true) {
  background: hsla($color1, 75%);

  @if $blur {
    @include strong-blur();
  }
}

@mixin blur() {
  backdrop-filter: blur(var(--blur));
}

@mixin contrast-blur() {
  backdrop-filter: blur(6px) brightness(80%) contrast(80%) saturate(120%);
}

@mixin strong-blur() {
  backdrop-filter: blur(var(--strong-blur));
}

@mixin cardShadows($color1, $color2) {
  box-shadow:
    inset 0 0 1.6rem -0.8rem $color1,
    0 0.2rem 3.6rem -1.6rem $color2;
}

@mixin textShadow() {
  text-shadow: 0 0.1rem 0.3rem hsla(var(--base-100-string), 15%);
}

@mixin strongTextShadow() {
  text-shadow: 0 0.1rem 0.3rem hsla(var(--base-100-string), 30%);
}

@mixin transitionIn($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-in-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-in-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionOut($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-out-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-out-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionInSlow($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-in-slow-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-in-slow-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionOutSlow($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-out-slow-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-out-slow-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin gpuRenderStyles() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 100rem;
  -moz-perspective: 100rem;
  -ms-perspective: 100rem;
  perspective: 100rem;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@mixin gradientBorder($darkColor, $lightColor) {
  background: linear-gradient(30deg, $darkColor 0%, $lightColor 60%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@function calculateCardSize($property) {
  $size: var(--standard-card-width);

  @if $property ==height {
    $size: var(--standard-card-height);
  }

  $size: calc($size - 2 * var(--border-size) - 2 * var(--card-padding));

  @return $size;
}
